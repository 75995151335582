import React, { useState } from "react"
import { Box, Button, Container, Typography, useTheme } from "@mui/material"
import Slider from "react-slick"
import * as styles from "./articlesnew.module.css"
import ArticlesCarouselNew from "./articlesCarouselNew"
import LeftArrowNew from "./leftArrowNew"
import RightArrowNew from "./rightArrowNew"
import { Link } from "gatsby"

const ArticlesSectionNew = () => {
  const theme = useTheme()
  const [slideIndex, setSlideIndex] = useState(0)

  return (
    <section className={styles.articleSection}>
      <Container maxWidth="md">
        <Box
          sx={{
            position: "relative",
            marginBottom: "5rem",
          }}
        >
          <Typography
            variant="h2"
            className="head2"
            sx={{
              // fontSize: 32,
              fontFamily: `"Poppins", "sans-serif" `,
              fontWeight: 400,
              marginTop: "10px",
              marginBottom: "10px",
              // [theme.breakpoints.down("sm")]: {
              //   fontSize: 24,
              // },
            }}
          >
            Our Articles
          </Typography>
        </Box>
      </Container>
      <ArticlesCarouselNew
        slideIndex={slideIndex}
        setSlideIndex={setSlideIndex}
      />
      <Container maxWidth="md">
        <Box className={styles.carouselArrowsMobile}>
          <LeftArrowNew
            onClick={() => {
              setSlideIndex(-1)
            }}
          />
          <RightArrowNew
            onClick={() => {
              setSlideIndex(1)
            }}
          />
        </Box>
        <Link className={styles.viewMoreLink} to="/blog">
          <Button
            variant="outlined"
            sx={{
              color: "#000000",
              fontSize: "16px",
              fontFamily: "Roboto",
              textTransform: "capitalize",
              border: "2px solid #636363",
              borderRadius: "0",
              minHeight: "40px",
              minWidth: "140px",
              marginBottom: "10px",
              textDecoration: "none",
            }}
            className={styles.viewMoreButton}
          >
            View More
          </Button>
        </Link>
      </Container>
    </section>
  )
}

export default ArticlesSectionNew
