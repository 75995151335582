import React from "react"
import chartData from "../../../config/chartData"
import data from "./data"
import { Bar } from "react-chartjs-2"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
} from "chart.js"
import theme from "../../../theme"
import { Box, ThemeProvider } from "@mui/material"
import ChartDataLabels from "chartjs-plugin-datalabels"

export default function performanceChart() {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    ChartDataLabels,
    Tooltip,
    Legend
  )

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          border: "1px solid black",
          // width: "500px",
          width: "100%",
          maxWidth: "650px",
          height: "fit-content",
          // paddingBottom: "1.5rem",
          // display: "inline-block",
          "@media (max-width:600px)": {
            width: "100%",
            maxWidth: "600px",
          },
          "@media (min-width:600px) and (max-width:760px)": {
            width: "100%",
            maxWidth: "600px",
          },
          "@media (min-width:760px) and (max-width:960px)": {
            width: "100%",
            maxWidth: "600px",
          },
          "@media (min-width:960px) and (max-width:1200px)": {
            width: "100%",
          },
        }}
      >
        <Box
          sx={{
            background: "grey",
            display: "flex",
            // gap: "1rem",
            width: "100%",
            // justifyContent: "center",
            // alignContent: "center",
            // "@media (min-width:600px) and (max-width:959px)": {
            //   gap: "3rem",
            // },
            // "@media (min-width:1050px) ": {
            //   gap: "2.5rem",
            // },
            // "@media (min-width:450px) and (max-width:599px) ": {
            //   gap: "0.5rem",
            // },
            // "@media (max-width:450px)": {
            //   gap: "0.5rem",
            // },
          }}
        >
          {/* <p className="text6 chart-title-1 p-second-title">ytd % return</p> */}
          <p className="text6 chart-title-1 p-second-title">2022 returns</p>
          <p className="text6 chart-title-2">Block or Fund</p>
        </Box>
        {/* <Box
          sx={{
            marginRight: "1rem",
            marginLeft: "1rem",

            "@media (max-width:600px)": {
              marginLeft: "1.5rem",
              marginRight: "1.5rem",
            },
          }}
        > */}
        <Bar
          width="100%"
          height="100%"
          // style={{ paddingLeft: "2rem", paddingRight: "2rem" }}
          style={{
            objectFit: "contain",
            maxWidth: "650px",
            width: "100% !important",
            height: "100% !important",
            backgroundSize: "contain",
            paddingLeft: "0.5rem",
            display: "inline-flex",
          }}
          options={{
            indexAxis: "y",
            maintainAspectRatio: true,
            aspectRatio: function getsize() {
              return window.innerWidth < 320 ? 2 / 3 : 1 / 1
            },
            layout: {
              padding: {
                left: function getleft() {
                  return window.innerWidth < 360 ? 15 : 25
                },
                right: function getright() {
                  return window.innerWidth < 360 ? 0 : 15
                },
              },
            },
            scales: {
              x: {
                ticks: {
                  display: false,
                  autoSkip: false,
                  stepSize: function getSize() {
                    return window.innerWidth < 400 ? 20 : 18
                  },
                },
                offset: true,
                grid: {
                  display: true,
                  drawBorder: false,
                  drawOnChartArea: true,
                  lineWidth: 0.5,

                  color: context =>
                    context.tick.value === 0 ? "rgba(0, 0, 0, 0.3)" : "white",
                },
              },
              y: {
                grid: {
                  display: false,
                  drawBorder: false,
                  drawTicks: true,
                },
                offset: true,
                beginAtZero: false,
                position: "right",

                ticks: {
                  autoSkip: false,
                  align: "left",
                  font: {
                    weight: 300,
                    size: function getsize() {
                      return window.innerWidth <= 360
                        ? 11
                        : window.innerWidth > 360 && window.innerWidth < 500
                        ? 12
                        : 15
                    },
                    family: "Roboto",
                  },
                },
              },
            },
            plugins: {
              title: {
                display: false,
              },
              legend: {
                display: false,
              },
              datalabels: {
                anchor: function (context) {
                  var value = context.dataset.data[context.dataIndex]
                  return value < 0 ? "start" : "end"
                }, // remove this line to get label in middle of the bar
                align: function (context) {
                  var value = context.dataset.data[context.dataIndex]
                  return value < 0 ? "start" : "end"
                },

                padding: 0,
                formatter: val => `${val}%`,
                labels: {
                  value: {
                    color: function (context) {
                      var value = context.dataset.data[context.dataIndex]
                      return value < 0 ? "red" : "black"
                    },

                    padding: "2rem",
                    font: function getsize() {
                      return window.innerWidth <= 360
                        ? { size: 11, weight: 300, family: "Roboto" }
                        : window.innerWidth > 360 && window.innerWidth < 576
                        ? { size: 12, weight: 300, family: "Roboto" }
                        : { size: 14, weight: 300, family: "Roboto" }
                    },
                  },
                },
              },
            },

            responsive: true,
          }}
          data={data}
        />
        {/* </Box> */}
      </Box>
    </ThemeProvider>
  )
}
