import React from "react"
import { Box, Container, Typography } from "@mui/material"
import * as styles from "./animation.module.css"
import "../../../styles/typography.css"

export default function AnimationDiv(props) {
  return (
    <Box width="100vw" sx={props.style} className={styles.animationdiv}>
      <Box width="100%" className={styles.innerdiv}>
        <Typography
          variant="h6"
          className="head5"
          sx={{ color: "white", marginBottom: 0 }}
        >
          {props.text} &gt; {props.text} &gt; {props.text} &gt; {props.text}{" "}
          &gt;
          {props.text} &gt; {props.text} &gt; {props.text} &gt; {props.text}{" "}
          &gt;
          {props.text} &gt; {props.text} &gt; {props.text} &gt; {props.text}{" "}
          &gt; &nbsp;
        </Typography>
      </Box>
    </Box>
  )
}
