const chartData = [
  {
    label: "Secure 2",
    value: "16.7",
  },
  {
    label: "Balanced 2",
    value: "24.2",
  },
  {
    label: "Growth 2",
    value: "9.0",
  },
  {
    label: "Alphabets",
    value: "12.1",
  },
  {
    label: "Core",
    value: "1.0",
  },
  {
    label: "ZeroCarbon",
    value: "21.5",
  },
  // {
  //   label: "S&P500",
  //   value: "-17.5",
  // },
  {
    label: "Nasdaq 100",
    value: "-13.9",
  },
  {
    label: ["KraneShares Carbon ETF"],
    value: "-9.3",
  },
  {
    label: ["ARK Innovation ETF"],
    value: "-48.1",
  },
]

export default chartData
