import React from "react"
import {
  Box,
  Grid,
  Typography,
  Container,
  createTheme,
  ThemeProvider,
} from "@mui/material"
import theme from "../../../src/theme"
import * as styles from "./content.module.css"
import "../../styles/typography.css"

export default function Content(props) {
  return (
    <ThemeProvider theme={theme}>
      <section className="content-section">
        <Box width="100%" sx={props.style}>
          <Container
            sx={props.innerContainerStyle}
            maxWidth={false}
            display="flex"
          >
            <Box
              width="50%"
              height="300px"
              sx={{
                [theme.breakpoints.down("sm")]: {
                  width: "100%",
                },
              }}
            >
              <img
                src={props.img}
                style={{
                  margin: "0",
                  width: "100%",
                  height: "300px",
                  objectFit: "cover",
                }}
              ></img>
            </Box>
            <Box width="50%" sx={props.styleright}>
              <Box width={"100%"} className={styles.textbox}>
                <Box m="auto" sx={props.textMaxWidth}>
                  <Typography
                    variant="h2"
                    className="head2"
                    sx={{
                      color: "white",
                      margin: "0",
                      fontWeight: 400,
                    }}
                  >
                    {props.text}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Container>
        </Box>
      </section>
    </ThemeProvider>
  )
}
