import React, { useEffect, useRef, useState } from "react"
import Slider from "react-slick"
import iPhone from "../../../images/iphone.png"
import * as styles from "../../homepage/mobilecarouselsection/carousel.module.css"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import MobileRightArrow from "./rightArrow"
import MobileLeftArrow from "./leftArrow"
import { Box, useTheme } from "@mui/material"
import blocksBalanced from "../../../images/blocks-Balanced.jpg"
import blocksCommodities from "../../../images/blocks-Commodities.jpg"
import blocksCurrencies from "../../../images/blocks-Currencies.jpg"
import blocksMobility from "../../../images/blocks-Mobility.jpg"
import blocksNextgen from "../../../images/blocks-Nextgen.jpg"
import blocksOmics from "../../../images/blocks-Omics.jpg"

var settings = {
  dots: false,
  arrows: true,
  infinite: true,
  speed: 500,
  centerMode: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  draggable: true,
  prevArrow: <MobileLeftArrow></MobileLeftArrow>,
  nextArrow: <MobileRightArrow></MobileRightArrow>,
  responsive: [
    {
      breakpoint: 600,
      settings: {
        centerPadding: "0px",
        slidesToShow: 1,
      },
    },
  ],
}

const MobileCarousel = () => {
  const theme = useTheme()
  const slider = useRef()
  return (
    <Box
      sx={{
        width: "350px",
        height: "640px !important",
        objectFit: "cover",
        backgroundImage: `url(${iPhone})`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        marginTop: "40px",
        marginBottom: "20px",
        position: "relative",
        [theme.breakpoints.down("sm")]: {
          width: "220px",
          height: "450px !important",
        },
        [theme.breakpoints.between("sm", "md")]: {
          width: "220px",
          height: "450px !important",
        },
      }}
    >
      <Box
        sx={{
          width: "283px",

          position: "absolute",
          top: "11.45%",
          left: "5.9%",
          bottom: "5%",
          right: "0px",
          [theme.breakpoints.down("sm")]: {
            width: "195px",
            top: "10.5%",
          },
          [theme.breakpoints.between("sm", "md")]: {
            width: "195px",
            top: "10.5%",
          },
        }}
      >
        <Slider {...settings} ref={slider}>
          <img
            src={blocksBalanced}
            width="100%"
            className={styles.mobileimg}
          ></img>
          <img
            src={blocksCommodities}
            width="100%"
            className={styles.mobileimg}
          ></img>
          <img
            src={blocksCurrencies}
            width="100%"
            className={styles.mobileimg}
          ></img>
          <img
            src={blocksMobility}
            width="100%"
            className={styles.mobileimg}
          ></img>
          <img
            src={blocksNextgen}
            width="100%"
            className={styles.mobileimg}
          ></img>
          <img
            src={blocksOmics}
            width="100%"
            className={styles.mobileimg}
          ></img>
        </Slider>
      </Box>
      <Box
        width="100px"
        height="4px"
        sx={{
          borderRadius: "40px",
          backgroundColor: "black",
          position: "absolute",
          bottom: "3.2%",
          left: "32%",
          [theme.breakpoints.down("md")]: {
            bottom: "6.5%",
            left: "28%",
          },
        }}
      ></Box>
    </Box>
  )
}

export default MobileCarousel
