import React from "react"
import * as styles from "./carousel.module.css"

function MobileRightArrow({ style, onClick }) {
  return (
    <div
      className={styles.rightArrow}
      id="right-arrow-slick-about"
      style={{
        ...style,
        position: "absolute",
        top: "45%",
        right: "-27%",
        width: "41px",
        height: "41px",
        cursor: "pointer",
        backgroundColor: "transparent",
        zIndex: "10",
        borderRadius: "100%",
        border: "1.5px solid #b5b5b5",
      }}
      onClick={onClick}
    >
      <svg
        width="12"
        height="18"
        viewBox="0 0 12 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translateX(-30%) translateY(-50%)",
        }}
      >
        <path
          d="M1.38591 17.7998C1.02596 17.6881 0.744789 17.4995 0.637105 17.1443C0.595763 17.0144 0.588946 16.8772 0.617224 16.7444C0.645501 16.6115 0.70805 16.4868 0.799628 16.3807C0.865249 16.3043 0.936898 16.2324 1.014 16.1656L8.66454 9.13988C8.7124 9.09685 8.75727 9.04833 8.847 8.9641C8.77896 8.92931 8.71455 8.88886 8.65457 8.84325C6.09342 6.49585 3.53427 4.14601 0.977107 1.79373C0.590243 1.44309 0.500506 1.05674 0.711886 0.679541C0.775812 0.566006 0.865831 0.466574 0.97572 0.388123C1.08561 0.309669 1.21274 0.25407 1.34833 0.225164C1.48392 0.19626 1.62473 0.19474 1.76102 0.220713C1.89731 0.246685 2.02583 0.299526 2.1377 0.375587C2.21666 0.431469 2.29098 0.492674 2.36005 0.558691C5.16315 3.12948 7.96659 5.70271 10.7704 8.27837C11.2938 8.75902 11.2938 9.24608 10.7704 9.72673C8.01445 12.2517 5.2602 14.7789 2.50761 17.3082C2.28626 17.5114 2.06591 17.7083 1.75981 17.7998H1.38591Z"
          fill="#999595"
        />
      </svg>
    </div>
  )
}

export default MobileRightArrow
