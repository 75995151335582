import React, { Component } from "react"
import {
  Box,
  Grid,
  Typography,
  Container,
  createTheme,
  ThemeProvider,
  autocompleteClasses,
} from "@mui/material"
import theme from "../theme"
import blockImage from "../images/blocks-icon.gif"
import fundImage from "../images/fund-icon.gif"
import strategyImage from "../images/strategy-icon.gif"
import purpleImg from "../images/purple_section.jpg"
import Chart from "../images/PerformanceChart.png"
import pinkImg from "../images/pink_section.jpg"
import Layout from "../components/layout"
import ArticlesSectionNew from "../components/homepage/articlesnew/indexArticles"
import AnimationDiv from "../components/homepage/animationSection/animationDiv"
import FooterMenu from "../components/footermenu"
import "../styles/app.scss"
import "../styles/typography.css"
import "../styles/homepage.scss"
import SEO from "../components/seo"
import ChartNew from "../components/homepage/performancechart/performanceChart"

import phoneFrontImg from "../images/app/99r-phone-home-for-website_352px.gif"
import phoneHoldingsImg from "../images/app/99r-phone-portfolio-for-website_352px.gif"
import phoneStockMemoImg from "../images/app/99r-phone-stock-for-website_352px.gif"
import bannerBackground from "../images/TrioBanner-shifts.gif"
import Content from "../components/homepage/content"
import yellowBuilding from "../images/yellow-building.jpg"
import greenGirl from "../images/green_girl.jpg"

import { Link, useStaticQuery, graphql } from "gatsby"

import Img from "gatsby-image/withIEPolyfill"
import MobileCarousel from "../components/homepage/mobilecarouselsection/mobilecarousel"
import FaqNew from "../components/homepage/faqsNew/indexFaq"

//import Slider from "react-slick"
//import "slick-carousel/slick/slick.css"
//import "slick-carousel/slick/slick-theme.css"

export default function HomePage2() {
  const getStarted = () => {
    return (
      <Link
        to="https://wealth.99rises.com/signup"
        rel="nofollow"
        className="circle1-new"
        state={{
          modal: true,
        }}
      >
        <p className="link-text">start here</p>
      </Link>
    )
  }

  const imagesData = useStaticQuery(graphql`
    query homepage2ImagesQuery {
      phoneHandImage: file(name: { eq: "phone-hand-new" }) {
        name
        relativePath
        childImageSharp {
          fixed(width: 300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      iphone13MiniGrowthImage: file(name: { eq: "iPhone-13-mini-growth" }) {
        name
        relativePath
        childImageSharp {
          fixed(width: 250) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      safeHands: file(name: { eq: "safe-hands" }) {
        name
        relativePath
        childImageSharp {
          fixed(width: 150) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Hedge Fund strategies available to everybody"></SEO>
      <ThemeProvider theme={theme}>
        <div className="homepage2">
          <section className="hero-banner2">
            <div
              style={{
                position: "absolute",
                width: "100%",
                height: "442px",
                zIndex: -1,
              }}
            >
              <img
                className="video-player"
                src={bannerBackground}
                width="100%"
                height="442px"
                style={{ objectFit: "cover", margin: "0" }}
              ></img>
            </div>
            <Container
              width={"100%"}
              className="hero-box"
              maxWidth={false}
              sx={{ maxWidth: "1200px" }}
            >
              <Container
                m="auto"
                maxWidth={false}
                sx={{
                  maxWidth: "1100px",
                  [theme.breakpoints.down("md")]: {
                    padding: 0,
                  },
                }}
              >
                <Typography
                  className="head1"
                  variant="h1"
                  sx={{
                    "@media (min-width:600px) and (max-width:920px)": {
                      fontSize: "42px",
                    },
                    "@media (max-width:600px)": {
                      fontSize: "32px",
                    },
                  }}
                >
                  Empowering every one to engage, invest, and shape the future
                </Typography>
              </Container>
            </Container>
          </section>
          <section className="phone-hand-section">
            <Box
              Width="100%"
              display="flex"
              sx={{
                [theme.breakpoints.down("sm")]: {
                  flexDirection: "column",
                },
              }}
            >
              <Box
                width="50%"
                className="phone-hand-left"
                display="flex"
                justifyContent="center"
                alignItems="flex-end"
                flexDirection="column"
                sx={{
                  [theme.breakpoints.down("sm")]: {
                    width: "100%",
                    minHeight: "442px",
                    alignItems: "center",
                  },
                  [theme.breakpoints.between("sm", "md")]: {
                    alignItems: "center",
                  },
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                    transform: "translateX(-100px)",
                    width: "437px",
                    maxWidth: "100%",
                    maxWidth: "calc(100% - 150px)",
                    [theme.breakpoints.between("xs", "sm")]: {
                      transform: "translateX(-20px)",
                      maxWidth: "calc(100% - 40px)",
                      width: "350px",
                    },
                    [theme.breakpoints.between("sm", "md")]: {
                      transform: "translateX(-10px)",
                      width: "400px",
                      maxWidth: "calc(100% - 40px)",
                    },
                  }}
                >
                  <Img
                    fixed={imagesData.phoneHandImage.childImageSharp.fixed}
                    objectFit="contain"
                    objectPosition="50% 50%"
                    alt="phone hand"
                    style={{
                      height: "auto",
                      width: "100%",
                      maxWidth: "90%",
                      aspectRatio: "1",
                      backgroundColor: "white",
                      borderRadius: "50%",
                    }}
                  />
                  <Box
                    sx={{
                      position: "absolute",
                      bottom: "-8%",
                      right: "-10%",
                      [theme.breakpoints.between("sm", "md")]: {
                        bottom: "-8%",
                        right: "-3%",
                      },
                      [theme.breakpoints.between("xs", "sm")]: {
                        bottom: "-10%",
                        right: "-7%",
                      },
                    }}
                    className="circle-bar-new"
                  >
                    {getStarted()}
                  </Box>
                </Box>
              </Box>

              <Box
                width="50%"
                sx={{
                  display: "flex",
                  flexDirection: "Column",
                  [theme.breakpoints.down("sm")]: {
                    width: "100%",
                  },
                }}
              >
                <Container
                  maxWidth="md"
                  sx={{
                    marginLeft: "0.5rem",
                    paddingTop: "1rem",
                    paddingBottom: "6.5rem",
                    [theme.breakpoints.down("sm")]: {
                      paddingLeft: "0.2rem !important",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    },
                    [theme.breakpoints.between("sm", "md")]: {
                      paddingBottom: "3rem",
                      marginLeft: "0",
                    },
                  }}
                >
                  <div
                    className="description-new"
                    style={{ maxWidth: "590px" }}
                  >
                    <Typography
                      className="head2"
                      variant="h2"
                      sx={{ fontWeight: "400", margin: "1.45rem 0 1rem" }}
                    >
                      Invest in your goals, values, and interests
                    </Typography>
                    <ul>
                      <li className="text1">
                        Put purpose at the center of your investing plans
                      </li>
                      <li className="text1">
                        Leverage hedge fund strategies available only to the
                        rich before
                      </li>
                      <li className="text1">
                        Lean on our investment team's expertise as your needs
                        and priorities change
                      </li>
                      {/* <li>Get relevant feeds of news and learning</li> */}
                    </ul>
                  </div>
                </Container>

                <div style={{ backgroundColor: "#4791FF" }}>
                  <Container
                    maxWidth="md"
                    sx={{
                      [theme.breakpoints.down("sm")]: {
                        paddingLeft: "0.2rem !important",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        maxWidth: "460px",
                        paddingTop: "1rem",
                        marginLeft: "0.5rem",
                        paddingBottom: "2.5rem",
                        display: "flex",
                        flexDirection: "column",

                        [theme.breakpoints.down("md")]: {
                          maxWidth: "540px",
                        },
                        [theme.breakpoints.between("sm", "md")]: {
                          paddingBottom: "1rem",
                          marginLeft: "0rem",
                        },
                      }}
                    >
                      <div style={{ position: "relative" }}>
                        <Typography
                          variant="h5"
                          sx={{
                            textAlign: "left",
                            color: "white",
                            paddingBottom: "0.1rem",
                            marginBottom: 0,
                            fontFamily: "Poppins",
                            overflow: "hidden",
                          }}
                          className="phone-hand-right-end-section head4"
                        >
                          ENGAGE IN THE JOURNEY
                        </Typography>
                      </div>
                      <p
                        style={{
                          textAlign: "justify",
                          color: "white",
                          paddingTop: "0.8rem",
                          fontFamily: "Roboto",
                          fontStyle: "italic",
                        }}
                        className="text2"
                      >
                        Shape your own wealth-building plans and learn along the
                        way. We’ll actively manage your stock investments,
                        balancing rewards and risks against your objectives.
                      </p>
                    </Box>
                  </Container>
                </div>
              </Box>
            </Box>
          </section>
          <AnimationDiv
            text=" We offer a range of blocks "
            style={{ backgroundColor: "#1685D3" }}
          />
          <Content
            style={{
              backgroundColor: "#49a882",
            }}
            innerContainerStyle={{
              display: "flex !important",
              flexDirection: "row-reverse",
              maxWidth: "1200px",
              [theme.breakpoints.between("sm", "md")]: {
                padding: "0 !important",
              },
              [theme.breakpoints.between("md", "lg")]: {
                padding: "0 !important",
              },
              [theme.breakpoints.down("sm")]: {
                flexDirection: "column",
                padding: "0 !important",
              },
            }}
            img={greenGirl}
            text="Pick blocks, not stocks"
            styleright={{
              display: "flex",
              [theme.breakpoints.down("sm")]: {
                width: "100%",
                height: "300px",
              },
            }}
            textMaxWidth={{
              maxWidth: "320px",
              [theme.breakpoints.down("sm")]: {
                maxWidth: "320px",
              },
            }}
          ></Content>
          <section className="block-content-section">
            <Container
              maxWidth={false}
              sx={{
                maxWidth: "1150px",
                paddingTop: "2rem",
                paddingBottom: "2rem",
              }}
            >
              <Grid container spacing={4}>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    [theme.breakpoints.down("sm")]: {
                      justifyContent: "center",
                    },
                  }}
                >
                  <MobileCarousel />
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  sx={{
                    marginTop: "4.2rem",
                    [theme.breakpoints.down("sm")]: {
                      marginTop: 0,
                    },
                  }}
                >
                  <Box>
                    <Box
                      sx={{
                        textAlign: "left",
                        maxWidth: "500px",
                        [theme.breakpoints.down("sm")]: {
                          margin: "auto",
                          width: "100%",
                          paddingLeft: "0.5rem",
                        },
                      }}
                      className="block-content"
                    >
                      <p className="text1 m-0">
                        A block is a collection of stocks managed to meet
                        objectives related to performance, risk management, and
                        purposes.
                      </p>
                      <p className="text2 margin">
                        Blocks provide access to a broad range of strategies and
                        asset classes:
                      </p>

                      <ul>
                        <li className="text2">
                          Long-short hedge fund strategies
                        </li>
                        <li className="text2">Impact investing at low mins </li>
                        <li className="text2">
                          Crypto and other asset types via ETFs
                        </li>
                      </ul>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              <div></div>
              <Box className="circle-bar-new" sx={{ marginTop: "1.5rem" }}>
                {getStarted()}
              </Box>
              <div className="activity-paragraph">
                <p className="text2">
                  We actively manage your blocks to reduce volatility and
                  capitalize on opportunities.
                </p>
              </div>
            </Container>
          </section>
          <AnimationDiv
            text=" Engage and learn as your wealth grows "
            style={{ backgroundColor: "#FF6E00" }}
          />
          <Content
            style={{ backgroundColor: "#FFAE0C" }}
            img={yellowBuilding}
            innerContainerStyle={{
              display: "flex !important",
              maxWidth: "1200px",
              [theme.breakpoints.between("sm", "md")]: {
                padding: "0 !important",
              },
              [theme.breakpoints.between("md", "lg")]: {
                padding: "0 !important",
              },
              [theme.breakpoints.down("sm")]: {
                flexDirection: "column",
                padding: "0 !important",
              },
            }}
            text="An experience that ties learning in"
            styleright={{
              display: "flex ",
              [theme.breakpoints.down("sm")]: {
                width: "100%",
                height: "300px",
                fontSize: "28px",
              },
            }}
            textMaxWidth={{
              maxWidth: "440px",
              [theme.breakpoints.down("sm")]: {
                maxWidth: "420px",
              },
            }}
          ></Content>
          <section>
            <Container
              maxWidth={false}
              sx={{ paddingTop: "3.5rem", maxWidth: "1200px" }}
            >
              <Phones3></Phones3>
            </Container>
          </section>
          <section className="daily-interest-section">
            <Container
              maxWidth="md"
              sx={{ paddingTop: "3rem", paddingBottom: "5rem" }}
            >
              <div className="circle-bar-new">{getStarted()}</div>
              <Box maxWidth={false} sx={{ maxWidth: "460px", margin: "auto" }}>
                <div style={{ position: "relative" }}>
                  <Typography
                    variant="h5"
                    sx={{
                      textAlign: "left",
                      color: "black",
                      fontSize: "19px",
                      paddingBottom: "0.1rem",
                      fontWeight: 500,
                      marginBottom: 0,
                      fontFamily: "Poppins",
                      lineHeight: "22px",
                      margin: "auto",
                      marginBottom: 0,
                      paddingTop: "3rem",
                      paddingLeft: "0.1rem",
                      textTransform: "uppercase",
                    }}
                    className="daily-content-heading head4"
                  >
                    Daily Content Matching your interests
                  </Typography>
                </div>

                <p
                  style={{
                    textAlign: "justify",
                    marginBottom: 0,
                    // fontSize: "17px",
                    fontStyle: "italic",
                    // paddingTop: "0.15rem",
                    fontFamily: "Roboto",
                    paddingTop: "0.8rem",
                    // fontWeight: 400,
                    // lineHeight: "1.3em",
                  }}
                  className="text2"
                >
                  Our goal is to keep you engaged in building your wealth and
                  the impacts your money can have.
                </p>
                <p
                  style={{
                    textAlign: "justify",

                    // fontSize: "17px",
                    fontStyle: "italic",
                    paddingTop: "0.2rem",
                    fontFamily: "Roboto",
                    // fontWeight: 400,
                    // lineHeight: "1.3em",
                  }}
                  className="text2"
                >
                  On any day, you can skim for top highlights or you can dig
                  into stories, strategies, markets, or stocks.
                </p>
              </Box>
            </Container>
          </section>
          <AnimationDiv
            text=" 99rises Performance "
            style={{ backgroundColor: "#4791FF" }}
          />
          <Content
            style={{ backgroundColor: "#876FFF" }}
            img={purpleImg}
            innerContainerStyle={{
              display: "flex !important",
              flexDirection: "row-reverse",
              maxWidth: "1200px",
              [theme.breakpoints.between("sm", "md")]: {
                padding: "0 !important",
              },
              [theme.breakpoints.between("md", "lg")]: {
                padding: "0 !important",
              },
              [theme.breakpoints.down("sm")]: {
                flexDirection: "column",
                padding: "0 !important",
              },
            }}
            text="Our blocks are designed for safety"
            styleright={{
              display: "flex ",
              [theme.breakpoints.down("sm")]: {
                width: "100%",
                height: "300px",
                fontSize: "28px",
              },
            }}
            textMaxWidth={{
              maxWidth: "360px",
              [theme.breakpoints.down("sm")]: {
                maxWidth: "320px",
              },
            }}
          ></Content>
          <section className="chart-section">
            <Container
              maxWidth={false}
              sx={{ maxWidth: "1150px", padding: "4.2rem 1rem" }}
            >
              <Grid container spacing={8}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  container
                  direction="row"
                  justifyContent="start"
                  alignItems="center"
                  sx={{
                    [theme.breakpoints.up("md")]: {
                      textAlign: "left",
                    },
                    [theme.breakpoints.down("md")]: {
                      justifyContent: "center",
                    },
                  }}
                >
                  <Box>
                    <h3 className="chart-heading head3">Relative to S&P 500</h3>
                    <ChartNew />
                  </Box>
                  <Box
                    sx={{
                      backgroundColor: "white",
                      color: "white",
                      textAlign: "left",
                      "@media (min-width:600px) and (max-width:760px)": {
                        width: "100%",
                        maxWidth: "600px",
                      },
                      "@media (min-width:760px) and (max-width:960px)": {
                        width: "100%",
                        maxWidth: "600px",
                      },
                    }}
                    className="notes"
                  >
                    <p className="text5">Notes:</p>
                    <ol>
                      <li className="text5">
                        Relative performance calculated as absolute performance
                        minus S&P500 performance.
                      </li>
                      {/* <li className="text5">
                        YTD is year to date through the end of December 2022.
                        YTD
                      </li> */}
                      <li className="text5">
                        {" "}
                        99rises Secure 2, Balanced 2 and Growth 2 can be
                        compared to S&P 500 and Nasdaq 100.
                      </li>
                      <li className="text5">
                        {" "}
                        99rises ZeroCarbon block can be compared to the
                        KraneShares Carbon ETF.
                      </li>
                      <li className="text5">
                        {" "}
                        99rises Core and Alphabets can be compared to the S&P
                        500, Nasdaq 100, and the ARK Innovation ETF.
                      </li>
                    </ol>
                  </Box>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="flex-start"
                >
                  <Box
                    sx={{
                      display: "flex",
                      gap: "2.5rem",
                      "@media (max-width:600px)": {
                        flexDirection: "column",
                      },
                      "@media (min-width:960px)": {
                        marginTop: "2.3rem",
                      },
                    }}
                  >
                    <Box
                      sx={{ textAlign: "left" }}
                      className="blocks-performance"
                    >
                      <p className="text2">
                        99rises blocks have outperformed the Nasdaq 100 by 15%
                        to 38% in 2022
                      </p>
                      <p
                        style={{ marginTop: "0.7rem", paddingTop: "0.3rem" }}
                        className="text2"
                      >
                        Our risk management is performing well despite market
                        volatility.
                      </p>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "1rem",
                      }}
                    >
                      <p
                        className="text2"
                        style={{
                          margin: 0,
                          fontStyle: "italic",
                          textAlign: "left",
                        }}
                      >
                        "my first hedge fund investment and it's affordable"
                      </p>
                      <p
                        className="text2"
                        style={{
                          margin: 0,
                          fontStyle: "italic",
                          textAlign: "left",
                        }}
                      >
                        "investing in my values was simple and fun"
                      </p>
                      <p
                        className="text2"
                        style={{
                          margin: 0,
                          fontStyle: "italic",
                          textAlign: "left",
                        }}
                      >
                        {" "}
                        "bright spot in my portfolio right now"
                      </p>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      alignContent: "center",
                      justifyContent: "center",
                      justifyItems: "center",
                      [theme.breakpoints.between("sm", "md")]: {
                        padding: 0,
                        marginTop: "2rem",
                      },
                      [theme.breakpoints.down("sm")]: {
                        marginTop: "2.5rem",
                      },
                    }}
                  >
                    <div className="circle-bar-new">{getStarted()}</div>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </section>
          <AnimationDiv
            text=" journey toward greater wealth "
            style={{ backgroundColor: "#FF5064" }}
          />
          <Content
            style={{
              backgroundColor: "#E55CAB",
            }}
            innerContainerStyle={{
              display: "flex !important",
              maxWidth: "1200px",
              [theme.breakpoints.between("sm", "md")]: {
                padding: "0 !important",
              },
              [theme.breakpoints.between("md", "lg")]: {
                padding: "0 !important",
              },
              [theme.breakpoints.down("sm")]: {
                flexDirection: "column",
                padding: "0 !important",
              },
            }}
            img={pinkImg}
            text="It starts with 3 easy steps"
            styleright={{
              display: "flex",
              [theme.breakpoints.down("sm")]: {
                width: "100%",
                height: "300px",
              },
            }}
            textMaxWidth={{
              maxWidth: "360px",
              [theme.breakpoints.down("sm")]: {
                maxWidth: "300px",
              },
            }}
          ></Content>

          <section className="steps-section">
            <Container
              maxWidth={false}
              sx={{ maxWidth: "1100px", paddingTop: "3rem" }}
            >
              <Box
                width="100%"
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                  [theme.breakpoints.down("sm")]: {
                    flexDirection: "column",
                    gap: "2rem",
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "0.3rem",
                  }}
                >
                  <img src={strategyImage} className="steps-image"></img>
                  <Typography
                    variant="h2"
                    sx={{ fontWeight: "400", marginTop: "0.3rem" }}
                  >
                    -1-
                  </Typography>
                  <p className="steps-title text2">
                    Establish your strategy through a guided interview
                  </p>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "0.3rem",
                  }}
                >
                  <img src={fundImage} className="steps-image"></img>
                  <Typography
                    variant="h2"
                    sx={{ fontWeight: "400", marginTop: "0.3rem" }}
                  >
                    -2-
                  </Typography>
                  <p className="steps-title text2">
                    Fund your account from your bank account
                  </p>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "0.3rem",
                  }}
                >
                  <img src={blockImage} className="steps-image"></img>
                  <Typography
                    variant="h2"
                    sx={{
                      fontWeight: "400",
                      marginTop: "0.3rem",
                    }}
                  >
                    -3-
                  </Typography>
                  <p className="steps-title text2">
                    Pick your first block to start the journey
                  </p>
                </Box>
              </Box>
              <Box sx={{ margin: "4rem auto" }}>
                <div className="circle-bar-new"> {getStarted()} </div>
              </Box>
            </Container>
          </section>
          <section className="fees-minimum-and-lockup-section">
            <Box
              sx={{
                display: "flex",
                [theme.breakpoints.down("sm")]: {
                  flexDirection: "column",
                },
              }}
              width="100%"
            >
              <Box
                width="50%"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  [theme.breakpoints.down("sm")]: {
                    width: "100%",
                  },
                }}
              >
                <Box sx={{ background: "#666666" }}>
                  <Container
                    maxWidth={false}
                    sx={{
                      maxWidth: "480px",
                      marginTop: "0.7rem",
                      marginBottom: "0.7rem",
                      [theme.breakpoints.down("sm")]: {
                        maxWidth: "auto",
                        marginLeft: "0rem !important",
                      },
                      [theme.breakpoints.between("sm", "md")]: {
                        paddingRight: 0,
                      },
                    }}
                  >
                    <Typography
                      variant="h5"
                      className="head4"
                      sx={{
                        textAlign: "left",
                        margin: 0,
                        color: "white",
                        fontWeight: "400",
                        fontFamily: "Poppins",
                        textTransform: "uppercase",
                      }}
                    >
                      Minimums & Fees
                    </Typography>
                    <p className="upper-p text3">
                      $5,000 per account, 1% annual fees
                    </p>
                  </Container>
                </Box>
                <Box className="grey-hand-background">
                  <Container
                    maxWidth={false}
                    sx={{
                      maxWidth: "480px",
                      marginTop: "1.5rem",
                      [theme.breakpoints.down("sm")]: {
                        maxWidth: "auto",
                        marginLeft: "0rem !important",
                      },
                    }}
                  >
                    <p
                      className="text3"
                      style={{
                        margin: 0,
                        textAlign: "left",
                        paddingBottom: "0.3rem",
                      }}
                    >
                      We take your privacy and security seriously
                    </p>
                    <ul>
                      <li className="text3">
                        High standards of security and protection
                      </li>
                      <li className="text3">
                        Two-factor login with 256-bit encryption
                      </li>
                      <li className="text3">
                        SIPC insurance per account up to $500,000
                      </li>
                    </ul>
                  </Container>
                </Box>
              </Box>
              <Box
                width="50%"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  [theme.breakpoints.down("sm")]: {
                    width: "100%",
                  },
                }}
              >
                <Box sx={{ background: "#9E8800" }}>
                  <Container
                    maxWidth={false}
                    sx={{
                      maxWidth: "560px",
                      margin: "0.7rem auto 0.7rem 0.5rem",
                      [theme.breakpoints.down("sm")]: {
                        marginLeft: "0rem",
                      },
                    }}
                  >
                    <Typography
                      variant="h5"
                      sx={{
                        textAlign: "left",
                        margin: 0,
                        color: "white",
                        fontWeight: "400",
                        textTransform: "uppercase",
                      }}
                      className="head4"
                    >
                      No lock-ups
                    </Typography>
                    <p className="upper-p text3">
                      It’s your money and you can withdraw any time
                    </p>
                  </Container>
                </Box>
                <Box sx={{ background: "#F0EDD9", height: "230px" }}>
                  <Container
                    maxWidth={false}
                    sx={{
                      maxWidth: "590px",
                      marginLeft: "0.5rem",
                      marginTop: "1.5rem",
                      [theme.breakpoints.down("sm")]: {
                        marginLeft: "0rem",
                      },
                    }}
                  >
                    <p className="text3">
                      We will be with you every step of the way. Our experience
                      and rigorous investment process enables us to build you a
                      portfolio of blocks that meet your goals, values, and
                      interests in an experience like no other.
                    </p>
                  </Container>
                </Box>
              </Box>
            </Box>
          </section>
          <ArticlesSectionNew />
          <FaqNew />
        </div>
      </ThemeProvider>
    </Layout>
  )
}

function Phones3() {
  return (
    <Grid container spacing={1}>
      <Grid
        item
        xs={12}
        sm={4}
        md={4}
        lg={4}
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <img
          src={phoneFrontImg}
          width={270}
          style={{ marginBlockEnd: "auto" }}
        />
        <p className="phone-title text2">
          Personalized news and learning feeds
        </p>
      </Grid>
      <Grid
        item
        xs={12}
        sm={4}
        md={4}
        lg={4}
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <img
          src={phoneHoldingsImg}
          width={270}
          style={{ marginBlockEnd: "auto" }}
        />
        <p className="phone-title text2">
          Strategies and stock picks at your fingertips
        </p>
      </Grid>
      <Grid
        item
        xs={12}
        sm={4}
        md={4}
        lg={4}
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <img
          src={phoneStockMemoImg}
          width={270}
          style={{ marginBlockEnd: "auto" }}
        />
        <p className="phone-title text2">
          Explanations that teach as your wealth grows
        </p>
      </Grid>
    </Grid>
  )
}
