import React from "react"
import {
  Box,
  Container,
  Grid,
  useTheme,
  Paper,
  IconButton,
  InputBase,
  Typography,
} from "@mui/material"
import * as styles from "./faqsnew.module.css"
import FaqSearchIcon from "../../../images/searchblack.svg"
import CloseIcon from "@mui/icons-material/Close"
import FaqCardNew from "./faqCardNew"

const faqsData = [
  {
    question: "What is 99rises?",
    answer: (
      <>
        99rises is a managed investment platform that provides low-cost,
        low-minimums access to hedge fund strategies (“alts”) and impact
        investing in separately managed customer accounts. As investment
        advisers, we are a fiduciary, which means we act in your interests ahead
        of our own. We will ask you a bit about yourself, your investment goals
        and set you up for success.
      </>
    ),
  },
  {
    question: `What are “alts”?`,
    answer: (
      <>
        “Alts” or alternative investment strategies offer investors a choice
        over traditional investment strategies that generally allocate capital
        on a “buy and hold” basis (i.e. “long only funds”) to stocks, bonds, and
        cash. “Alts” seek to create positive performance in all market
        conditions over a given investment horizon. As such, often performance
        is not measured versus a broad market index, or a benchmark, but in
        absolute terms along with other factors e.g. volatility.`
      </>
    ),
  },
  {
    question: "How liquid is my capital?",
    answer: (
      <>
        It’s your money in a separately managed account and you can have access
        to it within 3-5 business days. At 99rises, we leverage technology to
        eliminate barriers to most people that may have had reason in the past
        including “locking up” your capital.
      </>
    ),
  },

  {
    question: "How often can I update my portfolio of blocks?",
    answer: (
      <>
        You can change your information as frequently as you like. However,
        after your initial investment, any changes to portfolio allocations will
        be adjusted on the next rebalance (at least monthly).
      </>
    ),
  },
  {
    question: "How secure is my account?",
    answer: (
      <>
        Your assets are held at our custodian, Apex Clearing, one of the premier
        custodians across the brokerage technology industry. We protect your
        personal data and financial information using Secure Sockets Layer (SSL)
        and 256-bit encryption technology.
      </>
    ),
  },
  {
    question: "How do you keep my money safe?",
    answer: (
      <>
        Our brokerage firm, Tradier is a member of FINRA, the securities
        industry self-regulatory body. Your assets are held at our custodian
        Apex Clearing. Each is insured by the SIPC, premier insurer of the
        brokerage industry, up to $500,000 per account, of which $250,000 can be
        applied to cash balances. This can protect against losses of account
        assets in the event that 99rises or Tradier liquidates. What you should
        remember is that this does not protect against market changes or risks
        in your account. The risks inherent in investing are tradeoffs you make
        to pursue investment returns. We design our blocks to aim for the
        highest projected returns given the associated risk level.
      </>
    ),
  },
  {
    question: "How do you decide what block is right for me?",
    answer: (
      <>
        Using guided interviews, our rules-based platform computes a risk score
        and establishes an investment profile that can drive the selection of
        the initial block choices for the customer. At this stage, we are
        focused on picking your initial long-short block which will provide a
        “safety prioritized” core to your portfolio. What we mean by this is a
        block that is broad in its composition and focused on managing
        volatility as current markets make clear can be quite harmful to passive
        strategies. We will expand blocks over time and provide tools for
        evaluating the best choices based on your goals, values, and interests.
      </>
    ),
  },
  {
    question: "What are my risks?",
    answer: (
      <>
        As with most investments, positive returns are not guaranteed and you
        may lose some or all of the money you have invested. Alternative
        strategies are also exposed to the same asset class and market liquidity
        issues as other investments. In addition, although hedging is intended
        to limit or reduce investment risk, it may not achieve that effect. In
        some cases, hedging may even increase losses. Lastly, there are risks
        that will not or cannot be hedged.
      </>
    ),
  },
]

const buildRegEx = (str, keywords) => {
  return new RegExp(
    "(?=.*?\\b" + keywords.split(" ").join(")(?=.*?\\b") + ").*",
    "i"
  )
}

const test = (str, keywords) => {
  return buildRegEx(str, keywords).test(str)
}

export default function FaqNew() {
  const theme = useTheme()
  const [searchKeyword, setSearchKeyword] = React.useState("")
  const [faqs, setFaqs] = React.useState(faqsData)
  const [resetExpanded, setResetExpanded] = React.useState(false)

  const searchFaqs = () => {
    const tempFaqs = [...faqsData]
    const searchKeywords = searchKeyword.split(" ")
    const searchResult =
      searchKeyword.length > 0
        ? tempFaqs.filter(
            faq =>
              test(faq.question, searchKeyword) ||
              test(faq.answer, searchKeyword)
          )
        : tempFaqs
    setFaqs([...searchResult])
    setResetExpanded(true)
  }

  React.useEffect(() => {
    if (searchKeyword === "") searchFaqs()
  }, [searchKeyword])

  return (
    <section>
      <Box
        width="100%"
        height="67.5px"
        sx={{
          background: "#666666",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="h2"
          className="head2"
          sx={{
            color: "white",
            margin: "0",
            // fontWeight: "400",
            fontFamily: "Poppins",
            marginTop: "0.3rem",
            marginBottom: "0.3rem",
          }}
        >
          FAQs
        </Typography>
      </Box>
      <Box width="100%" sx={{ background: "#f3f3f3" }}>
        <Container
          maxWidth={false}
          sx={{
            maxWidth: "1200px",
            paddingTop: "1rem",
            paddingBottom: "2.5rem",
          }}
        >
          <Grid container spacing={4} sx={{ marginTop: "0px" }}>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              lg={6}
              container
              direction="row"
              justifyContent="center"
              alignItems="flex-start"
              sx={{
                margin: "0 !important",
                [theme.breakpoints.down("sm")]: {
                  justifyContent: "center",
                },
              }}
            >
              <Typography
                variant="h3"
                className="head3"
                sx={{
                  color: "black !important",
                  fontWeight: "400 !important",
                  margin: 0,
                  marginTop: "0.8rem",
                }}
              >
                Have a question? We are here to help.
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              lg={6}
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <Paper
                component="form"
                sx={{
                  p: "2px 4px",
                  display: "flex",
                  alignItems: "center",
                  width: "420px",
                  height: 55,
                  margin: "1.1rem auto auto 0.5rem ",
                  [theme.breakpoints.down("sm")]: {
                    width: "100%",
                  },
                }}
                className={styles.searchNew}
                onSubmit={e => {
                  e.preventDefault()
                }}
              >
                <IconButton
                  sx={{
                    p: "10px 10px 10px 10px ",
                    margin: "auto auto auto 10px",
                    color: "black",
                    [theme.breakpoints.down("sm")]: {
                      p: "10px ",
                    },
                  }}
                  aria-label="search"
                  onClick={searchFaqs}
                >
                  <img
                    src={FaqSearchIcon}
                    alt="search icon"
                    style={{ margin: 0 }}
                    width="24px"
                    height="24px"
                  />
                </IconButton>
                <InputBase
                  sx={{
                    ml: 1,
                    flex: 1,
                    fontSize: 14,
                    color: "#ffffff",
                  }}
                  placeholder="Search"
                  inputProps={{ "aria-label": "Search" }}
                  className={styles.searchInputNew}
                  value={searchKeyword}
                  onChange={e => setSearchKeyword(e.target.value)}
                  onKeyUp={e => {
                    if (e.key === "Enter") {
                      searchFaqs()
                    }
                  }}
                />
                {searchKeyword.length > 0 ? (
                  <IconButton
                    sx={{
                      p: "10px 10px 10px 10px",
                      margin: "auto 10px auto auto",
                      [theme.breakpoints.down("sm")]: {
                        p: "10px ",
                      },
                    }}
                    aria-label="search"
                    onClick={() => {
                      setSearchKeyword("")
                    }}
                  >
                    <CloseIcon
                      sx={{ height: 23, width: 23, color: "#000000" }}
                    />
                  </IconButton>
                ) : null}
              </Paper>
              <div className={styles.faqsContainerNew}>
                {faqs.length > 0 ? (
                  faqs.map(faq => (
                    <FaqCardNew
                      question={faq.question}
                      answer={faq.answer}
                      resetExpanded={resetExpanded}
                      setResetExpanded={setResetExpanded}
                    />
                  ))
                ) : (
                  <Typography className={styles.subHeader}>
                    No results found.
                  </Typography>
                )}
              </div>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </section>
  )
}
