import React from "react"
import Accordion from "@mui/material/Accordion"
import AccordionDetails from "@mui/material/AccordionDetails"
import AccordionSummary from "@mui/material/AccordionSummary"
import Typography from "@mui/material/Typography"
import FaqExpandIcon from "../../../images/faqExpand.svg"
import FaqCollapseIcon from "../../../images/faqCollapse.svg"
import * as styles from "./faqsnew.module.css"
import { Paper, useTheme } from "@mui/material"

const FaqCardNew = ({ question, answer, resetExpanded, setResetExpanded }) => {
  const theme = useTheme()
  const [expanded, setExpanded] = React.useState(false)

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  React.useEffect(() => {
    if (resetExpanded) {
      setExpanded(false)
      setResetExpanded(false)
    }
  }, [resetExpanded])

  return (
    <>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
        elevation={0}
        //   className="faqcard"
        sx={{
          padding: "6px 0.5rem 6px 0.3rem ",
          "&:before": {
            display: "none",
          },
          borderRadius: "8px !important",
          marginBottom: "0px !important",
          marginLeft: "0.5rem !important",
          position: "relative",
          background: "transparent !important",
          border: "none !important",
          [theme.breakpoints.down("sm")]: {
            padding: "5px 0px",
          },
        }}
      >
        <AccordionSummary
          expandIcon={null}
          elevation={0}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          sx={{
            padding: 0,
            width: "calc(100% - 50px)",
            marginBottom: 0,
            minHeight: "35px",
          }}
        >
          <Typography
            variant="h6"
            className="head5"
            sx={{
              color: "black",
              fontSize: "18px",
              fontWeight: 500,
              textAlign: "left",
              width: "90%",
              fontFamily: "Poppins",
              [theme.breakpoints.down("sm")]: {
                fontSize: 16,
                lineHeight: "1.2em",
              },
            }}
          >
            {question}
          </Typography>
          <Paper
            component="div"
            elevation={0}
            className={styles.expandExpandIconNew}
            sx={{
              boxShadow: "none",
              right: -5,
              transform: "translate(100%, -50%)",
              width: 28,
              height: 28,
              paddingTop: "0.1rem",
              border: "none !important",
              [theme.breakpoints.down("sm")]: {
                width: 24,
                height: 24,
              },
            }}
          >
            {expanded === "panel1" ? (
              <img
                src={FaqCollapseIcon}
                alt="collapse icon"
                className={styles.expandicon}
              />
            ) : (
              <img
                src={FaqExpandIcon}
                alt="expand icon"
                className={styles.expandicon}
              />
            )}
          </Paper>
        </AccordionSummary>
        <AccordionDetails
          sx={{ padding: 0, width: "calc(100% - 40px)", marginBottom: "0" }}
        >
          <Typography
            className="text4"
            sx={{
              fontSize: 16,
              color: "rgba(136, 136, 136, 1)",
              fontWeight: 400,
              color: "black",
              textAlign: "left",
              marginBottom: "0px",

              [theme.breakpoints.down("sm")]: {
                fontSize: 14,
              },
            }}
          >
            {answer}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </>
  )
}

export default FaqCardNew
