import chartData from "../../../config/chartData"
const labels = chartData.map(e => e.label)

const data = {
  labels,
  datasets: [
    {
      data: chartData.map(e => e.value),
      type: "bar",

      barPercentage: 0.2,
      minBarLength: 2,
      barThickness: function getsize() {
        return window.innerWidth < 400 ? 20 : 25
      },
      backgroundColor: [
        "#26a64a",
        "#26a64a",
        "#26a64a",
        "#26a64a",
        "#26a64a",
        "#26a64a",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
      ],
      borderColor: [
        "#26a64a",
        "#26a64a",
        "#26a64a",
        "#26a64a",
        "#26a64a",
        "#26a64a",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
      ],
      fillColor: [
        "#26a64a",
        "#26a64a",
        "#26a64a",
        "#26a64a",
        "#26a64a",
        "#26a64a",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
      ],
      strokeColor: [
        "#26a64a",
        "#26a64a",
        "#26a64a",
        "#26a64a",
        "#26a64a",
        "#26a64a",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
      ],
    },
  ],
}

export default data
